// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import {observer} from 'mobx-react'

// fragments
import {fragments} from 'services/get-fragments'

// react-router
import {Link} from 'react-router-dom'

// semantic-ui
import {Header, Segment, Container, Image, Button} from 'semantic-ui-react';
import HomeContainerOne from "./HomeContainerOne";
import HomeContainerTwo from "./HomeContainerTwo";
import HomeContainerThree from "./HomeContainerThree";
import HomeContainerFour from "./HomeContainerFour";
import styled from "styled-components";
import backgroundImg from '../../src/assets/idf_brain_bg_half_tablet.svg';
import placeholderImg from '../../src/assets/idf_line_bg_tablet.svg';

const ApiContainer = styled(Container)`
    min-width: 2% !important;
    min-height: 2%;
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
`;

const ItemContainer = styled(Container)`
    min-width: 50% !important;
    min-height: 70%;
    margin: 100px 0;
`;

const TextContainer = styled(Container)`
    display: inline !important;
    minWidth: 50vw !important;
`;

const ImgContainer = styled(Container)`
    background: url(${backgroundImg}) no-repeat;
    width: 25vw !important;
    height: 30vw;
    float: left;
    margin-bottom: 40px;
    
    @media (max-width: 770px) {
        display: none !important;
    }
`;

const ImgPlaceholder = styled(Container)`
    background: url(${placeholderImg}) no-repeat;
    width: 25vw !important;
    height: 8.5vw;
    float: right;
    margin: 20px 0;
    
    @media (max-width: 1950px) {
        display: none !important;
    }
`;

export const Home = observer(
    class Home extends React.Component {
        state = {
            apiContent: '',
            apiName: '',
            showContent: false
        };

        getApiData = () => {
            const link = "https://api.id-fabrik.io/hello-world"
            fetch(link)
                .then(res => res.json())
                .then(result => {
                    this.setState({
                        apiContent: result.welcome,
                        apiName: link,
                        showContent: true
                    });
                })
        }

        render() {
            return (
                <>
                    <Segment vertical textAlign='center' style={{
                        color: 'whitesmoke',
                        backgroundColor: 'slategray',
                        padding: '40px 0px',
                        margin: '0px !important'
                    }}>
                        <Image centered size='small' src='/custom-content/home-image.png'/>
                        <Header as='h1' style={{color: 'whitesmoke'}}>{fragments.Home.header}</Header>
                        <p>{fragments.Home.tagline}</p>
                        <Link to='/getting-started' data-testid='gettingStartedLink'>
                            <Button positive>{fragments.Home.gettingStartedButton}</Button>
                        </Link>
                        <Button negative onClick={this.getApiData}>{fragments.Home.apiButton}</Button>
                        {this.state.showContent &&
                            <>
                            <ApiContainer>
                                API Name: {this.state.apiName}
                            </ApiContainer>
                            <ApiContainer>
                                {this.state.apiContent}
                            </ApiContainer>
                            </>
                        }
                    </Segment>
                    <Segment vertical textAlign='center' style={{
                        backgroundColor: '#FFFFFF',
                        boxSizing: 'borderBox',
                        padding: '40px 0px',
                        margin: '0px !important',
                        border: 'none'
                    }}>
                        <ItemContainer fluid text textAlign='justified'>
                            <ImgContainer/>
                            <TextContainer>
                                <HomeContainerOne/>
                                <HomeContainerTwo/>
                                <ImgPlaceholder/>
                                <HomeContainerThree/>
                                <HomeContainerFour/>
                            </TextContainer>
                        </ItemContainer>
                    </Segment>
                </>
            )
        }
    })

export default Home;
