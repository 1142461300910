// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// semantic-ui
import { Container } from 'semantic-ui-react'
import styled from "styled-components";
import backgroundImg from "../assets/idf_brain_bg_side_right.svg";

const ItemContainer = styled(Container)`
    min-width: 50% !important;
    min-height: 70%;
    margin-top: 120px;
`;

const TextContainer = styled(Container)`
    min-width: 50vw !important;
    margin-top: 120px;
`;

const ImgContainer = styled(Container)`
    background: url(${backgroundImg}) no-repeat;
    width: 25vw !important;
    height: 30vw;
    float: left;
    margin-bottom: 40px;
    
    @media (max-width: 770px) {
        display: none !important;
    }
`;

export default observer(() => (
    <ItemContainer style={{ padding: '40px' }}>
        <ImgContainer/>
        <TextContainer>
            <fragments.Tab3.jsx />
        </TextContainer>
    </ItemContainer>
))
