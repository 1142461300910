// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

import { observer } from 'mobx-react'

// semantic-ui
import styled from "styled-components";
import logo from '../assets/id-fabrik_logo.svg'
import {withRouter} from "react-router-dom";

const FooterContainer = styled.div`
    box-sizing: border-box;
    background-color: #242424;
    width: 100%;
    padding: 40px 25%;
    
    @media (max-width: 450px) {
        padding: 40px 10%;
    }
`;

const Logo = styled.div`
    background: url(${logo}) no-repeat;
    width: 130px;
    height: 40px;
    cursor: pointer;
    border: none;
`;

const Text = styled.div`
    color: #D2CDC8;
    font-size: 18px;
    margin-top: 20px;
`;

const LinkContainer = styled.div`
    margin-top: 20px;
    display: inline-block;
`;

const Link = styled.a`
    color: #8C8986;
    font-size: 18px;
    font-weight: bold;
    padding-right: 20px;
    cursor: pointer;
    transition: 0.8s;
    
    &:hover {
        color: #D2CDC8;
    }
`;

function footerView() {
    const url = window.location.href;
    return !url.includes('/apis') && !url.includes('/admin/apis');
}

export const Footer = observer(
    class Footer extends React.Component {
        render () {
            return (
                <FooterContainer>
                    {footerView() &&
                        <>
                        <Logo/>
                        <Text>idf innovations- und digitalisierungsfabrik gmbh</Text>
                        <Text>Erich-Steinfurth Str. 8</Text>
                        <Text>10243 Berlin</Text>
                        </>
                    }
                    <LinkContainer>
                        <Link href={'https://s-cio.id-fabrik.de/impressum/'} target={'_blank'}>Impressum</Link>
                        <Link href={'https://s-cio.id-fabrik.de/datenschutzerklaerung/'} target={'_blank'}>Datenschutzerklärung</Link>
                    </LinkContainer>
                </FooterContainer>
            )
        }
    }
)

export default withRouter(Footer)

